.btn-close {
  color: black;
}

.lift-on-hover1 {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  transition: ease-in-out 0.3s;
}
.lift-on-hover2 {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  transition: ease-in-out 0.3s;
}
.lift-on-hover3 {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  transition: ease-in-out 0.3s;
}
.lift-on-hover4 {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  transition: ease-in-out 0.3s;
}

.features1:hover {
  cursor: pointer;
  .lift-on-hover1 {
    top: calc(50% - 2px);
    // border: 1px solid rgba(#f2f4f8, 0);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: translate(0%, -10%);
  }
}
.features2:hover {
  cursor: pointer;
  .lift-on-hover2 {
    top: calc(50% - 2px);
    // border: 1px solid rgba(#f2f4f8, 0);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: translate(0%, -10%);
  }
}
.features3:hover {
  cursor: pointer;
  .lift-on-hover3 {
    top: calc(50% - 2px);
    // border: 1px solid rgba(#f2f4f8, 0);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: translate(0%, -10%);
  }
}
.features4:hover {
  cursor: pointer;
  .lift-on-hover4 {
    top: calc(50% - 2px);
    // border: 1px solid rgba(#f2f4f8, 0);
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: translate(0%, -10%);
  }
}

.client-testi {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  transition: ease-in-out 0.3s;
}

.client-testi:hover {
  top: calc(20% - 2px);
  // border: 1px solid rgba(#f2f4f8, 0);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: translate(0%, -5%);
}
